<template>
    <!-- begin:: Aside -->
    <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
        <!--begin::Primary-->
        <div class="aside-primary d-flex flex-column align-items-center flex-row-auto">
            <!--begin::Brand-->
            <KTBrand></KTBrand>
            <!--end::Brand-->
            <!--begin::Nav Wrapper-->
            <div
                class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
                style="overflow: hidden"
            >
                <!--begin::Nav-->
                <ul class="nav flex-column" v-if="display" role="tablist">
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        v-if="role === 'Commercial' || role === 'admin'"
                    >
                        <router-link :to="{ name: 'admin/dashboard', query: { activeTable: 'home' } }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="9"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 9 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Home/Home.svg" />
                                </span>
                                <p class="m-0 p-0">Accueil</p>
                            </div>
                        </router-link>
                    </li>
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        v-if="role !== 'Commercial'"
                    >
                        <router-link :to="{ name: 'admin/dashboard', query: { activeTable: 'classes' } }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="1"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 1 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Home/Book.svg" />
                                </span>
                                <p class="m-0 p-0">Classes</p>
                            </div>
                        </router-link>
                    </li>
                    <li
                        v-if="!this.$store.getters.currentUser.demo && role !== 'Commercial'"
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                    >
                        <router-link :to="{ name: 'admin/dashboard', query: { activeTable: 'stagiaires' } }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="2"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 2 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Communication/Group.svg" />
                                </span>
                                <p class="m-0 p-0">Stagiaires</p>
                            </div>
                        </router-link>
                    </li>
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100"
                        :class="role === 'admin' ? 'd-block' : ''"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        v-if="role === 'admin'"
                    >
                        <router-link :to="{ name: 'admin/dashboard', query: { activeTable: 'clients' } }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="3"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex == 3 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Home/Building.svg" />
                                </span>
                                <p class="m-0 p-0">Clients</p>
                            </div>
                        </router-link>
                    </li>
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        hidden
                    >
                        <router-link :to="{ name: 'link' }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="4"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 4 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Communication/Share.svg" />
                                </span>
                                <p class="m-0 p-0">Validation d'inscription</p>
                            </div>
                        </router-link>
                    </li>
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        hidden
                    >
                        <router-link :to="{ name: 'link' }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="5"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 5 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Communication/Share.svg" />
                                </span>
                                <p class="m-0 p-0">Rapport d'erreur</p>
                            </div>
                        </router-link>
                    </li>
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100"
                        :class="role === 'admin' ? 'd-block' : ''"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        v-if="role === 'admin'"
                    >
                        <router-link :to="{ name: 'admin/dashboard', query: { activeTable: 'settings' } }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="6"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex == 6 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/General/Settings-2.svg" />
                                </span>
                                <p class="m-0 p-0">Paramètres</p>
                            </div>
                        </router-link>
                    </li>
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100"
                        :class="role === 'admin' ? 'd-block' : ''"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                    >
                        <a :href="plateformURL">
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="7"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex == 7 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/logos/si2p.svg" />
                                </span>
                                <p class="m-0 p-0">Plateforme</p>
                            </div>
                        </a>
                    </li>
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100"
                        :class="role === 'admin' ? 'd-block' : ''"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                    >
                        <a to="/dashboard" href="/dashboard">
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="8"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex == 8 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Home/Home.svg" />
                                </span>
                                <p class="m-0 p-0">Accueil Academy</p>
                            </div>
                        </a>
                    </li>
                </ul>
                <!--end::Nav-->
            </div>
            <!--end::Nav Wrapper-->
            <!--begin::Footer-->
            <div class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-20">
                <!--begin::Aside Toggle-->
                <!--
                <span
                    class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                    id="kt_aside_toggle"
                    ref="kt_aside_toggle"
                    data-placement="right"
                    data-container="body"
                    data-boundary="window"
                    v-b-tooltip.hover.right="'Raccourcies'"
                    v-if="this.$router.currentRoute['path'] == '/admin/dashboard'"
                >
                    <i class="ki ki-bold-arrow-back icon-sm"></i>
                </span>
            -->
                <!--end::Aside Toggle-->
            </div>
            <!--end::Footer-->
        </div>
        <!--end::Primary-->
    </div>
    <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
    display: none;
}
</style>

<script>
import { mapGetters } from 'vuex';
import KTLayoutAside from '@/assets/js/layout/base/aside.js';
import KTLayoutAsideMenu from '@/assets/js/layout/base/aside-menu.js';
import KTLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle.js';
import KTBrand from '@/view/layout/brand/Brand.vue';

export default {
    name: 'KTAdminAside',
    data() {
        return {
            insideTm: 0,
            outsideTm: 0,
            tabIndex: 1,
            role: null,
            display: false,
            plateformURL: process.env.VUE_APP_PLATEFORME_URL,
            navItems: [
                { name: 'classes', index: 1 },
                { name: 'stagiaires', index: 2 },
                { name: 'clients', index: 3 },
                { name: 'Validation_inscription', index: 4 },
                { name: 'rapport_erreurs', index: 5 },
                { name: 'paramètres', index: 6 },
                { name: 'plateforme', index: 7 },
                { name: 'academy', index: 8 },
                { name: 'home', index: 9 }
            ]
        };
    },
    components: {
        KTBrand
    },
    mounted() {
        this.$nextTick(() => {
            // Init Aside
            KTLayoutAside.init(this.$refs['kt_aside']);

            // Init Aside Menu
            KTLayoutAsideMenu.init(this.$refs['kt_aside_menu']);

            // Init Aside Toggle
            KTLayoutAsideToggle.init(this.$refs['kt_aside_toggle']);

            //Init Role
            this.setRole();

            this.$route.query.activeTable
                ? this.navItems.find((el) => el.name === this.$route.query.activeTable)
                    ? (this.tabIndex = this.navItems.find((el) => el.name === this.$route.query.activeTable).index)
                    : null
                : null;
        });
    },
    methods: {
        setActiveTab(event) {
            let target = event.target;
            if (!event.target.classList.contains('nav-link')) {
                target = event.target.closest('.nav-link');
            }

            const tab = target.closest('[role="tablist"]');
            const links = tab.querySelectorAll('.nav-link');

            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove('active');
            }

            // set clicked tab index to bootstrap tab
            this.tabIndex = parseInt(target.getAttribute('data-tab'));

            // set current active tab
            target.classList.add('active');
        },
        setRole() {
            if (this.role !== this.$store.getters.currentUser.role && this.$store.getters.currentUser.role) {
                this.role = this.$store.getters.currentUser.role;
                this.display = true;
            } else {
                setTimeout(() => {
                    this.setRole();
                }, 2000);
            }
        }
    },
    computed: {
        ...mapGetters(['layoutConfig', 'getClasses']),

        /**
         * Get extra classes for menu based on the options
         */
        asideMenuClass() {
            const classes = this.getClasses('aside_menu');
            if (typeof classes !== 'undefined') {
                return classes.join(' ');
            }
            return null;
        }
    },
    updated() {
        if (this.role !== this.$store.getters.currentUser.role && this.$store.getters.currentUser.role) {
            this.role = this.$store.getters.currentUser.role;
        }
    }
};
</script>
