var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('KTHeaderMobile',{staticStyle:{"z-index":"2"}}),(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[(_vm.asideEnabled)?_c('KTAdminAside',{staticStyle:{"z-index":"1001"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper",attrs:{"id":"kt_wrapper"}},[(
                    function () {
                        _vm.window.screen.availWidth > 992;
                    }
                )?_c('div',{staticClass:"adminTopBar adminTopBarRelative"},[_c('AdminTopBar')],1):_vm._e(),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid",attrs:{"id":"kt_content"}},[_c('div',{staticClass:"d-flex flex-column-fluid mt-5"},[_c('div',{class:{
                            'container-fluid': _vm.contentFluid,
                            container: !_vm.contentFluid
                        },staticStyle:{"max-width":"95%"}},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)])])])],1),_c('KTScrollTop')],1)}
var staticRenderFns = []

export { render, staticRenderFns }